import { Box } from '@shopify/polaris';
import React from 'react';
import DesktopPreviewImg from '../../assets/images/desktoppreview.png';
import { getIcon } from '../../helper/commonFunction';

const DesktopPreview = ({ formik, brandingData }) => {
    const { launcher_general, launcher_desktop } = formik.values;

    return (
        <>
            {launcher_general && <style>
                .desktop-preview-container .fab-icon-box .fab-icon-sec {'{'}
                    background-color: {`${launcher_general?.launcher_color}!important`};
                    color: {`${launcher_general?.launcher_font_color}!important`};
                {'}'}

                .desktop-preview-container .fab-icon-box .fab-icon-sec .fab-icon-img svg path{'{'}
                    fill: {`${launcher_general?.launcher_font_color}!important`};
                {'}'}
                .desktop-preview-container .fab-icon-box {'{'}
                    justify-content: {`${launcher_desktop?.desktop_placement === 'Left' ? 'flex-start' : 'flex-end'}!important`};
                {'}'}
                .desktop-preview-container .fab-icon-box .fab-icon-innerbox {'{'}
                    padding-bottom: {launcher_desktop?.desktop_bottom_spacing > 10 ? `${launcher_desktop?.desktop_bottom_spacing}px` : '10px'};
                    padding-left: {launcher_desktop?.desktop_placement === 'Left' && launcher_desktop?.desktop_side_spacing > 10 ? `${launcher_desktop?.desktop_side_spacing}px` : '10px'};
                    padding-right: {launcher_desktop?.desktop_placement === 'Right' && launcher_desktop?.desktop_side_spacing > 10 ? `${launcher_desktop?.desktop_side_spacing}px` : '10px'}
                {'}'}

            </style>}
            <Box padding={400}>
                <div className='desktop-preview-container'>
                    <div className='desktop-preview-box'>
                        <div className='desktop-pre-img'>
                            <img src={DesktopPreviewImg} />
                        </div>
                        <div className='fab-icon-box'>
                            <div className='fab-icon-innerbox'>
                                <div className='fab-icon-sec'>
                                    {launcher_desktop?.visibility === 'icon_with_text' || launcher_desktop?.visibility === 'icon' ? <div className='fab-icon-img'>
                                        {launcher_general?.icon_custom || (launcher_general?.icon && brandingData?.launcher_general?.icon_type) ? <img width={'30px'} height={'30px'} src={launcher_general?.icon_custom || `https://${launcher_general?.icon}`}/> : getIcon(launcher_general?.icon_value)}
                                    </div> : ''}
                                    {launcher_desktop?.visibility === 'icon_with_text' || launcher_desktop?.visibility === 'text' ? <div className='fab-icon-content'>
                                        <p>{launcher_general?.text}</p>
                                    </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
};

export default DesktopPreview;