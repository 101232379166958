import React, { useEffect, useState } from 'react';
import { Box, Button, DropZone, HorizontalStack, Label, Text, Thumbnail } from '@shopify/polaris';
import { DeleteMajor, NoteMajor } from '@shopify/polaris-icons';
import { encodeImageFileAsURL } from '../helper/commonFunction';


function DropZoneFile({fileAccept, fileActionTitle, fileActionHint, handleImportValue, fileLabel, fileType = 'file', fileValue, fileCustomIcon, fileIconType }) {
    const [customFile, setCustomFile] = useState(fileCustomIcon ? fileCustomIcon : '');
    const uploadedFile = (customFile || fileValue) && (
        <Box padding={200} borderColor='border' borderRadius='150' borderWidth='1'>
            <HorizontalStack blockAlign='center' align='space-between'>
                <HorizontalStack gap={200} blockAlign='center' >
                    <div className={`custom_icon ${fileLabel === 'Earn points icon' ? 'iwearnPoint' : fileLabel === 'Launcher icon' ? 'iwlauncherIcon' : 'iwredeemPoint'}`}>
                        <Thumbnail
                            size="small"
                            alt={customFile?.name || 'custom icon'}
                            source={
                                customFile?.name ? window.URL.createObjectURL(customFile) : (fileCustomIcon && !fileIconType) ? fileCustomIcon : `http://devrelo.identixweb.com/${fileLabel === 'Launcher icon' ? 'icon' : 'images'}/${fileValue}` || NoteMajor
                            }
                        />
                    </div>
                    <Text>{customFile?.name || (fileCustomIcon ? 'custom_icon.jpg' : fileValue)}</Text>
                    <Button icon={DeleteMajor} plain onClick={() => { setCustomFile(''); handleImportValue(''); }}></Button>
                </HorizontalStack>
            </HorizontalStack>
        </Box>
    );

    const handleUpload = async (files, accepted) => {
        try {
            setCustomFile(accepted[0]);
            const getBase64 = await encodeImageFileAsURL(accepted[0]);
            handleImportValue(getBase64);
        } catch (error) {
            console.log('error----------------- ', error);
        }
    };

    return (
        customFile || fileCustomIcon ? <><Label>{fileLabel}</Label><div>
            {uploadedFile}
        </div> </> : <Box paddingBlockStart={100}><DropZone label={fileLabel} allowMultiple={false} onDrop={handleUpload} type={fileType} accept={fileAccept}>
            <DropZone.FileUpload actionTitle={fileActionTitle} actionHint={fileActionHint} />
        </DropZone></Box>
    );
}

export default DropZoneFile;
