import React, { useEffect } from 'react';
import config_variable from '../helper/config';
import { useSelector } from 'react-redux';
import { SaveBar } from '@shopify/app-bridge-react';
import { PageActions } from '@shopify/polaris';

const AppBridgeSaveBar = ({ id, show, onClick, loading, discardClick, saveAction, discardAction, leaveConfirmationDisable, visible,fullWidth, isShowShopify, savbtn, savemsg, disabled }) => {
    // const { save_bar: { id, show, onClick, loading, discardClick, saveAction, discardAction, leaveConfirmationDisable, visible,fullWidth, isShowShopify, savbtn, savemsg } } = useSelector(state => state.appBridge);

    return (
        isShowShopify ? <SaveBar
            open={visible}
            id={id}
            >
            <button
                variant='primary'
                onClick={saveAction?.onAction}
                loading={loading}
                disabled={saveAction?.disabled}
            />
            <button
                loading={discardAction?.loading}
                onClick={discardAction?.onAction}
            />
        </SaveBar> : visible && <PageActions
            primaryAction={{
                content: savbtn,
                loading,
                onAction: onClick,
                disabled
            }}
            secondaryActions={[
                {
                    content: 'Discard',
                    onAction: discardClick
                }
            ]}
        />
    );
};

export default AppBridgeSaveBar;