const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const apiEndpoint = process.env.apiEndpoint;
const shop_name = params?.shop || 'dipika-vaghasiya-test.myshopify.com';
const embedded = params?.embedded ? 1 : 0;
const domain = process.env.Domain;
const siteName = process.env.SITE_NAME;
const siteHandle = process.env.SITE_HANDLE;
const secret = process.env.API_KEY;
const EXTENSION_UUID = '9dcad510-9c5b-422d-8039-67aa1b0ca2d3';
const EXTENSION_BLOCK_NAME = 'reward-block';
/* below config variable is use for Shopify app bridge */
const config = { apiKey: process.env.API_KEY, host: params.host, forceRedirect: true };
const config_variable = { 
    config, 
    shop_name, 
    apiEndpoint, 
    embedded, 
    siteName, 
    domain,
    siteHandle,
    secret,
    ACTION_ENCRYPT: 'encrypt',
    ACTION_DECRYPT: 'decrypt',
    EXTENSION_UUID,
    EXTENSION_BLOCK_NAME
};
export { config_variable };
