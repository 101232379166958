import { ContextualSaveBar, Provider, TitleBar } from '@shopify/app-bridge-react';
import React, { useCallback, useEffect, useState } from 'react';
import { config_variable } from '../../helper/config';
import { Box, Divider, Frame, Layout, LegacyCard, Page, Spinner, Tabs, Text, Toast } from '@shopify/polaris';
import Theme from './theme/Theme';
import Panel from './panel/Panel';
import Launcher from './launcher/Launcher';

import DefaultPreview from './DefaultPreview';
import DesktopPreview from './DesktopPreview';
import MobilePreview from './MobilePreview';
import { ApiCall, GetApiCall } from '../../helper/axios';
import CommonApiEndPoint from '../../helper/commonApiEndPoint';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Savebar from '../../common-components/Savebar';
import { useSelector } from 'react-redux';
import Footer from '../../common-components/Footer';
import { decodeImageFileAsURL } from '../../helper/commonFunction';


function Branding() {
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(0);
    const [brandingData, setBrandingData] = useState({});
    const [toastMessage, setToastMessage] = useState('');
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const launcher_id = useSelector(state => state?.data?.launcher_id);

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        []
    );

    const tabs = [
        {
            id: 'all-customers-fitted-2',
            content: 'Theme',
            accessibilityLabel: 'Theme',
            panelID: 'all-customers-fitted-content-2'
        },
        {
            id: 'accepts-marketing-fitted-2',
            content: 'Panel',
            panelID: 'accepts-marketing-fitted-Ccontent-2'
        },
        {
            id: 'accepts-marketing-fitted-3',
            content: 'Launcher',
            panelID: 'accepts-marketing-fitted-Ccontent-3'
        }
    ];

    const formik = useFormik({
        initialValues: brandingData,
        enableReinitialize: true,
        validationSchema: yup.object({
            launcher_desktop: yup.object({
                desktop_bottom_spacing: yup.number().required('Spacing must be 10px or greater').min(10, 'Spacing must be 10px or greater'),
                desktop_side_spacing: yup.number().required('Spacing must be 10px or greater').min(10, 'Spacing must be 10px or greater')
            }),
            launcher_mobile: yup.object({
                mobile_bottom_spacing: yup.number().required('Spacing must be 10px or greater').min(10, 'Spacing must be 10px or greater'),
                mobile_side_spacing: yup.number().required('Spacing must be 10px or greater').min(10, 'Spacing must be 10px or greater')
            }),
            launcher_general: yup.object({
                icon_type: yup.number(),
                icon_custom: yup.string().when('icon_type', {
                    is: (value) => value === 1 && brandingData?.icon_type === 0,
                    then: (schema) => schema
                        .required('icon required')
                        .test('icon_custom', 'File type must be .gif, .jpg, .png or .jpeg', value => {
                            if ((value && value.startsWith('data:'))) {
                                const file = decodeImageFileAsURL(value);
                                return file ? validImageTypes.includes(file?.type) : false;
                            } else {
                                return true;
                            }
                        })
                        .test('icon_dimension', 'Invalid image dimensions. Image must be a square with a minimum size of 90x90px.', value => {
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    const img = document.querySelector('.custom_icon.iwlauncherIcon img');
                                    if (value && img) {
                                        const iconDimension = {
                                            height: img.naturalHeight,
                                            width: img.naturalWidth
                                        };
                                        const dimentionalCheck = iconDimension.width > 90 && iconDimension.height > 90 && iconDimension.width === iconDimension.height;
                                        console.log('dimentionalCheck-------->', dimentionalCheck);

                                        resolve(dimentionalCheck);
                                    } else {
                                        resolve(true); // No image, skip validation
                                    }
                                }, 500); // Delay for 500ms
                            });
                        })
                })
            }),
            panel_program_card_settings: yup.object({
                earn_points_icon_custom: yup.string()
                    .test('earn_points_icon_custom', 'File type must be .gif, .jpg, .png or .jpeg', value => {
                        if ((value && value.startsWith('data:'))) {
                            const file = decodeImageFileAsURL(value);
                            return file ? validImageTypes.includes(file.type) : false;
                        } else {
                            return true;
                        }
                    })
                    .test('icon_dimension', 'Invalid image dimensions. Image must be a square with a minimum size of 90x90px.', value => {
                        return new Promise((resolve) => {
                            setTimeout(() => {
                                const img = document.querySelector('.custom_icon.iwearnPoint img');
                                if (value && img) {
                                    const iconDimension = {
                                        height: img.naturalHeight,
                                        width: img.naturalWidth
                                    };
                                    const dimentionalCheck = iconDimension.width > 90 && iconDimension.height > 90 && iconDimension.width === iconDimension.height;
                                    resolve(dimentionalCheck);
                                } else {
                                    resolve(true); // No image, skip validation
                                }
                            }, 300); // Delay for 500ms
                        });
                    }),
                redeem_points_icon_custom: yup.string()
                    .test('redeem_points_icon_custom', 'File type must be .gif, .jpg, .png or .jpeg', value => {
                        if ((value && value.startsWith('data:'))) {
                            const file = decodeImageFileAsURL(value);
                            return file ? validImageTypes.includes(file.type) : false;
                        } else {
                            return true;
                        }
                    })
                    .test('icon_dimension', 'Invalid image dimensions. Image must be a square with a minimum size of 90x90px.', value => {
                        return new Promise((resolve) => {
                            setTimeout(() => {
                                const img = document.querySelector('.custom_icon.iwredeemPoint img');
                                if (value && img) {
                                    const iconDimension = {
                                        height: img.naturalHeight,
                                        width: img.naturalWidth
                                    };
                                    const dimentionalCheck = iconDimension.width > 90 && iconDimension.height > 90 && iconDimension.width === iconDimension.height;
                                    resolve(dimentionalCheck);
                                } else {
                                    resolve(true); // No image, skip validation
                                }
                            }, 300); // Delay for 500ms
                        });
                    })
            })
        }),
        onSubmit: (values) => {
            setLoading('submitting');
            setTimeout(() => handleSubmitDetails(values), 500);
        }
    });

    async function getBrandingData() {
        try {
            const res = await GetApiCall('POST', `${CommonApiEndPoint.getBranding}`);
            if (res?.data?.status === 'SUCCESS') {
                const resData = res?.data?.data;
                delete resData['id'];
                delete resData['created'];
                delete resData['store_client_id'];
                delete resData['updated'];
                setBrandingData(resData);
                setLoading(false);
            } else {
                setToastMessage(res?.data?.message);
                toggleActive();
                setLoading(false);
            }
        } catch (error) {
            console.log('error--------------------', error);
        }
    }

    useEffect(() => {
        setLoading('page_load');
        setTimeout(() => getBrandingData(), 500);
    }, []);

    async function handleSubmitDetails(values) {
        try {
            const payload = {
                ...values,
                hide_launcher_on_urls_metaid: undefined,
                hide_launcher_on_urls_metaresponse: undefined,
                hide_launcher_on_urls_metavalue: undefined,
                theme_visibility: {
                    ...values.theme_visibility,
                    hide_launcher_in_url_contains: null
                }
            };
            const res = await ApiCall('POST', CommonApiEndPoint.updateBranding, payload);
            if (res?.data?.status === 'SUCCESS') {
                const resData = res?.data?.data;
                delete resData['id'];
                delete resData['created'];
                delete resData['store_client_id'];
                delete resData['updated'];
                setBrandingData(resData);
                formik.setSubmitting(true);
            } else {
                formik.setSubmitting(false);
            }
            setTimeout(() => setLoading(false), 500);
        } catch (error) {
            console.log('error----------------->>', error);
        }
    }

    const resetForm = () => {
        formik.handleReset();
    };

    const handleSubmit = () => {
        formik.handleSubmit();
    };

    const saveAction = {
        disabled: !formik.dirty || loading === 'submitting',
        loading: loading === 'submitting',
        onAction: handleSubmit
    };

    const discardAction = {
        disabled: !formik.dirty,
        loading: false,
        discardConfirmationModal: true,
        onAction: resetForm
    };

    return (
        <>
            <Frame>
                {process.env?.MODE !== 'local' && Object.keys(brandingData)?.length ? (
                    <Provider config={config_variable.config}>
                        <ContextualSaveBar
                            saveAction={saveAction}
                            discardAction={discardAction}
                            fullWidth
                            leaveConfirmationDisable={true}
                            visible={formik.dirty}
                        />
                        <TitleBar title="Branding" />
                    </Provider>
                ) : null}
                {loading === 'page_load' ? <div className="page_loader" key="loader" style={{ height: '-webkit-fill-available' }}>
                    <Spinner size="large" />
                </div> : <Page title={process.env?.MODE === 'local' ? 'Branding' : ''}>
                    {process.env?.MODE === 'local' && formik.dirty && <>
                        <style>
                            {'\
                            .Polaris-Page{\
                                margin-top: 56px;\
                            }'
                            }
                        </style>
                        <Savebar
                            savbtn='Save'
                            onClick={handleSubmit}
                            disabled={!formik.dirty || loading === 'submitting'}
                            loader={loading === 'submitting'}
                            onDiscard={resetForm}
                        />
                    </>}
                    <Layout>
                        <Layout.Section oneThird >
                            <LegacyCard>
                                <Box padding={400}>
                                    <Text as="h3" variant="headingMd">
                                        Edit branding
                                    </Text>
                                </Box>
                                <Divider />
                                {<Box>
                                    <Tabs
                                        tabs={tabs}
                                        selected={selected}
                                        onSelect={handleTabChange}
                                        fitted
                                    >
                                        {selected === 0 ? (
                                            <Theme
                                                formik={formik}
                                            />
                                        ) : selected === 1 ? (
                                            <Panel
                                                formik={formik}
                                                brandingData={brandingData}
                                            />
                                        ) : (
                                            <Launcher
                                                formik={formik}
                                                brandingData={brandingData}
                                            />
                                        )}
                                    </Tabs>
                                </Box>}
                            </LegacyCard>
                        </Layout.Section>
                        <Layout.Section>
                            <div
                                className={`preview-card ${selected === 2 ? 'desktop-mobile-container' : ''}`}
                            >
                                <LegacyCard>
                                    <Box padding={400}>
                                        <Text as="h3" variant="headingMd">
                                            Preview
                                        </Text>
                                    </Box>
                                    <Divider />
                                    {selected === 2 ? (
                                        <>
                                            {' '}
                                            {launcher_id === 'mobile' ? <MobilePreview formik={formik} brandingData={brandingData} /> : <DesktopPreview formik={formik} brandingData={brandingData} />} {' '}
                                        </>
                                    ) : (
                                        <DefaultPreview formik={formik} brandingData={brandingData} />
                                    )}
                                </LegacyCard>
                            </div>
                        </Layout.Section>
                    </Layout>
                    <Footer link={' https://www.identixweb.com/helpdesk/wholesale-personalized-pricing/app-features/settings-page/'} />
                </Page>}
                {active ? <Toast content={toastMessage || 'Request failed!'} onDismiss={toggleActive} duration={2000} /> : null}
            </Frame>
        </>
    );
}

export default Branding;