import { Box, Collapsible, Divider, FormLayout, InlineError, LegacyStack, Text, TextField, Tooltip, VerticalStack } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import Desktop from './Desktop';
import ArrowDownIcon from '../../../assets/images/arrow-down.svg';
import ArrowUpIcon from '../../../assets/images/arrow-up.svg';
import DesktopIcon from '../../../assets/images/desktop.svg';
import MobileIcon from '../../../assets/images/mobile.svg';
import Mobile from './Mobile';
import DeleteModal from '../../../common-components/pop_up_modal/DeleteModal';
import Input from '../../../common-components/Input';
import CheckList from '../../../common-components/ChoiceList';
import DropZoneFile from '../../../common-components/DropZoneFile';
import { useDispatch, useSelector } from 'react-redux';
import { handleLauncherId } from '../../../redux/slice/DataSlice';

const Launcher = ({ formik, brandingData }) => {
    const dispatch = useDispatch();
    const launcher_general = formik.values?.launcher_general;
    const launcher_id = useSelector(state => state?.data?.launcher_id);
    const [loading, setLoading] = useState(false);
    const [launcherID, setLauncherID] = useState(launcher_id);
    const [visible, setVisible] = useState(false);
    const [defaultColorSelected, setDefaultColorSelect] = useState(false);
    const [iconCustomLauncher, setCustomIconLauncher] = useState('');
    const defaultIcons = launcher_general?.default_icons;
    const primary_color = '#332105';
    const secondary_color = '#EBE9E6';  
    const defaultColorData = {
        launcher_color: '#332105',
        launcher_font_color: '#EBE9E6'
    };

    function handleToggle(id = false) {
        try {
            if (id && launcherID !== id) {
                setLauncherID(id);
                dispatch(handleLauncherId(id));
            } else {
                setLauncherID(false);
                dispatch(handleLauncherId(false));
            }
        } catch (error) {
            console.log('error------------------', error);
        }
    }

    function handleThemeSelection(e) {
        const name = e.target.name;
        const value = e.target.value;
        formik.setFieldValue(name, value);
    }

    const resetAllColor = () => {
        for (const key in defaultColorData) {
            if (Object.hasOwnProperty.call(defaultColorData, key)) {
                const colorValue = defaultColorData[key];
                formik.setFieldValue(`launcher_general.${key}`, colorValue);
            }
        }
        setDefaultColorSelect(true);
        setLoading(false);
        setVisible(false);
    };
    useEffect(() => {
        if (brandingData?.launcher_general?.icon_type) {
            formik.setFieldValue('launcher_general.icon_custom', brandingData?.launcher_general?.icon_value);
            setCustomIconLauncher(brandingData?.launcher_general?.icon_value);
        }
    }, []);

    useEffect(() => {
        try {
            if (formik.values?.launcher_general?.icon_type) {
                formik.setFieldValue('launcher_general.icon_custom', formik.values?.launcher_general?.icon_custom);
                setCustomIconLauncher(brandingData?.launcher_general?.icon_type ? brandingData?.launcher_general?.icon_value :  formik.values?.launcher_general?.icon_custom);
            } else {
                const icon = Object.values(defaultIcons)[0];
                formik.setFieldValue('launcher_general.icon_value', icon);
            }
           
        } catch (error) {
            console.log('error----------------', error);
        }
    }, [formik.values?.launcher_general?.icon_type]);

    const handleImportFileUpload = (value) => {
        setCustomIconLauncher(value);
        formik.setFieldValue('launcher_general.icon_custom', value);
    };

    const renderChildren = useCallback(
        (isSelected) => isSelected && (
            <Box>
                {formik.values?.launcher_general?.icon_type ? <div className='custom-icon-container'>
                    <DropZoneFile
                        // fileAccept={'.svg, .png'}
                        /* formik.values.image && nudgeData?.icon_type */
                        fileActionTitle={'Add icon'}
                        fileActionHint={'Size 90px by 90px'}
                        fileLabel={'Launcher icon'}
                        fileIconType={ brandingData?.launcher_general?.icon_type }
                        fileCustomIcon={iconCustomLauncher}
                        fileValue={formik.values?.launcher_general?.icon_value && brandingData?.launcher_general?.icon_custom ? formik.values?.launcher_general?.icon_custom :  formik.values?.launcher_general?.icon_value}
                        handleImportValue={(value) => handleImportFileUpload(value)} 
                    />
                </div> : <div className='default-icon-container'>
                    <div className='default-icon-box'>
                        {Object.values(defaultIcons).map((icon, index) => {
                            return (
                                <div className={`default-icons ${formik.values?.launcher_general?.icon_value === icon ? 'lnr_selected' : ''}`} key={index} onClick={() => formik.setFieldValue('launcher_general.icon_value', icon)}>
                                    <img src={`https://devrelo.identixweb.com/images/${icon}`} alt='gift' name='launcher_general.icon_value' />
                                </div>
                            );
                        })}
                    </div>
                </div>}
                {formik.errors?.launcher_general?.icon_custom || formik.touched?.launcher_general?.icon_custom ? <InlineError message={formik.errors?.launcher_general?.icon_custom} /> : null}
            </Box>
        ),
        [formik.values?.launcher_general?.icon_type, defaultIcons, formik.values?.launcher_general?.icon_value, formik.errors?.launcher_general?.icon_custom, formik.touched?.launcher_general?.icon_custom]
    );
    
    return (
        <>
            <Divider />
            <Box padding={300} >
                <LegacyStack alignment="center" distribution='fill'>
                    <LegacyStack.Item>
                        <Text as="h3" variant="headingMd">
                            Launcher color
                        </Text>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                        <Tooltip content='Revert to default'>
                            <div className='color-picker theme-color-default' onClick={() => setVisible(!visible)} style={{ borderColor: defaultColorSelected ? '#2C6ECB' : '' }}>
                                <div style={{backgroundColor: primary_color}} className='btn-revert-color'></div>
                                <div style={{backgroundColor: secondary_color}} className='btn-revert-color'></div>
                                {/* <Box><input type='color' name='theme_primary_color' value={primary_color} readOnly/></Box> */}
                                {/* <Box><input type='color' name='theme_secondary_color' value={secondary_color} readOnly/></Box> */}
                            </div>
                        </Tooltip>
                        <DeleteModal
                            deletePopUpActive={visible}
                            handleDeletePopUp={() => setVisible(false)}
                            popUpContent='Are you sure to reset it with default colors?'
                            primaryAction={{ content: 'Revert', loading: loading === 'revert', onAction: () => { setLoading('revert'); setTimeout(() => resetAllColor(), 500); }, destructive: true }}
                            secondaryActions={[{ content: 'Cancel', onAction: () => setVisible(false) }]}
                            popUpTitle={'Revert to default?'}
                        />
                    </LegacyStack.Item>
                </LegacyStack>
                <Box paddingBlockStart={200}>
                    <LegacyStack alignment="center" distribution='fill'>
                        <LegacyStack.Item>
                            <Input
                                label='Color'
                                type='value'
                                value={(formik.values?.launcher_general?.launcher_color.trimStart())}
                                handleChange={formik.setFieldValue}
                                name='launcher_general.launcher_color'
                                handleBlur={formik.setFieldTouched}
                                error={formik.errors?.launcher_general?.launcher_color}
                                touched={formik.touched?.launcher_general?.launcher_color}
                                connectedRight={
                                    <Box><input type='color' name='launcher_general.launcher_color' value={formik.values?.launcher_general?.launcher_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                }
                            />
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            <Input
                                label='Font'
                                type='value'
                                value={(formik.values?.launcher_general?.launcher_font_color.trimStart())}
                                handleChange={formik.setFieldValue}
                                name='launcher_general.launcher_font_color'
                                handleBlur={formik.setFieldTouched}
                                error={formik.errors?.launcher_general?.launcher_font_color}
                                touched={formik.touched?.launcher_general?.launcher_font_color}
                                connectedRight={
                                    <Box><input type='color' name='launcher_general.launcher_font_color' value={formik.values?.launcher_general?.launcher_font_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                }
                            />
                        </LegacyStack.Item>
                    </LegacyStack>
                </Box>
            </Box>
            <Divider/>
            <Box padding={300}>
                <VerticalStack gap={200}>
                    <Text as="h3" variant="headingMd">
                        Set text & icon
                    </Text>
                    <Input
                        label='Text'
                        type='value'
                        placeholder={'Reward'}
                        value={formik.values?.launcher_general?.text.trimStart()}
                        handleChange={formik.setFieldValue}
                        name='launcher_general.text'
                        handleBlur={formik.setFieldTouched}
                        error={formik.errors?.text}
                        touched={formik.touched?.text}
                        autoComplete="off"
                    />
                    <Box>
                        <LegacyStack vertical spacing='none'>
                            <CheckList
                                optionsData={[
                                    { label: 'Default icon', value: 0, renderChildren },
                                    { label: 'Upload custom icon', value: 1, renderChildren }
                                ]}
                                selected={[formik.values?.launcher_general?.icon_type]}
                                name='launcher_general.icon_type'
                                handleChange={(name, selected) => formik.setFieldValue(name, selected[0])}
                            />
                        </LegacyStack>
                    </Box>
                </VerticalStack>
            </Box>
            <Divider />
            <Box padding={300} >
                <button
                    className={'collapse-button-container'}
                    onClick={() => handleToggle('desktop')}
                    aria-expanded={launcherID === 'desktop'}
                    aria-controls="basic-collapsible">
                    <LegacyStack alignment="center">
                        <LegacyStack.Item fill>
                            <div className='branding-toogle-container'>
                                <div className='branding-color-title'>
                                    <img
                                        src={DesktopIcon}
                                        alt="color icon"
                                    />
                                </div>
                                <Text as="h3" variant="headingMd" fontWeight='medium'>
                                    Desktop
                                </Text>
                            </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            {launcherID === 'desktop' ? <img src={ArrowUpIcon} alt="arrow down icon" /> : <img src={ArrowDownIcon} alt="arrow down icon" />}
                        </LegacyStack.Item>
                    </LegacyStack>
                </button>
            </Box>
            <Collapsible
                open={launcherID === 'desktop'}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <Desktop formik={formik} />
            </Collapsible>
            <Divider />
            <Box padding={300} >
                <button
                    className={'collapse-button-container'}
                    onClick={() => handleToggle('mobile')}
                    aria-expanded={launcherID === 'mobile'}
                    aria-controls="basic-collapsible">
                    <LegacyStack alignment="center">
                        <LegacyStack.Item fill>
                            <div className='branding-toogle-container'>
                                <div className='branding-color-title'>
                                    <img
                                        src={MobileIcon}
                                        alt="color icon"
                                    />
                                </div>
                                <Text as="h3" variant="headingMd" fontWeight='medium'>
                                    Mobile
                                </Text>
                            </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            {launcherID === 'mobile' ? <img src={ArrowUpIcon} alt="arrow down icon" /> : <img src={ArrowDownIcon} alt="arrow down icon" />}
                        </LegacyStack.Item>
                    </LegacyStack>
                </button>
            </Box>
            <Collapsible
                open={launcherID === 'mobile'}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <Mobile formik={formik} />
            </Collapsible>


        </>
    );
};

export default Launcher;