import { Box, Collapsible, Divider, LegacyStack, Text } from '@shopify/polaris';
import React, { useState } from 'react';
import Visitor from './Visitor';
import ArrowDownIcon from '../../../assets/images/arrow-down.svg';
import ArrowUpIcon from '../../../assets/images/arrow-up.svg';
import VisitorIcon from '../../../assets/images/visitor.svg';
import MemberIcon from '../../../assets/images/member.svg';
import Member from './Member';
import CommonSetting from './CommonSetting';
import { useDispatch, useSelector } from 'react-redux';
import { handlePanelId } from '../../../redux/slice/DataSlice';

const Panel = ({ formik, brandingData }) => {
    const dispatch = useDispatch();
    const panel_id = useSelector(state => state?.data?.panel_id);
    const [panelId, setPanelId] = useState(panel_id);

    function handleMemberToggle(id = false) {
        try {
            if (id && panelId !== id) {
                setPanelId(id);
                dispatch(handlePanelId(id));
            } else {
                setPanelId(false);
                dispatch(handlePanelId(false));
            }
        } catch (error) {
            console.log('error------------------', error);
        }
    }

    return (
        <>
            <Divider />
            <Box padding={300} >
                <button
                    className={'collapse-button-container'}
                    onClick={() => handleMemberToggle('visitor')}
                    aria-expanded={panelId === 'visitor'}
                    aria-controls="basic-collapsible">
                    <LegacyStack alignment="center">
                        <LegacyStack.Item fill>
                            <div className='branding-toogle-container'>
                                <div className='branding-color-title'>
                                    <img
                                        src={VisitorIcon}
                                        alt="color icon"
                                    />
                                </div>
                                <Text as="h3" variant="headingMd" fontWeight='medium'>
                                    Visitor
                                </Text>
                            </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            {panelId === 'visitor' ? <img src={ArrowUpIcon} alt="arrow down icon" /> : <img src={ArrowDownIcon} alt="arrow down icon" />}
                        </LegacyStack.Item>
                    </LegacyStack>
                </button>
            </Box>
            <Collapsible
                open={panelId === 'visitor'}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <Visitor formik={formik} />
            </Collapsible>

            <Divider />
            <Box padding={300} >
                <button
                    className={'collapse-button-container'}
                    onClick={() => handleMemberToggle('member')}
                    aria-expanded={panelId === 'member'}
                    aria-controls="basic-collapsible">
                    <LegacyStack alignment="center">
                        <LegacyStack.Item fill>
                            <div className='branding-toogle-container'>
                                <div className='branding-color-title'>
                                    <img
                                        src={MemberIcon}
                                        alt="color icon"
                                    />
                                </div>
                                <Text as="h3" variant="headingMd" fontWeight='medium'>
                                    Member
                                </Text>
                            </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                            {panelId === 'member' ? <img src={ArrowUpIcon} alt="arrow down icon" /> : <img src={ArrowDownIcon} alt="arrow down icon" />}
                        </LegacyStack.Item>
                    </LegacyStack>
                </button>
            </Box>
            <Collapsible
                open={panelId === 'member'}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <Member formik={formik} />
            </Collapsible>
            <CommonSetting formik={formik} brandingData={brandingData}/>
        </>
    );
};

export default React.memo(Panel);